import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ToastService } from '@services/toast.service';
import { IAppState } from '@store/app.state';

import { BaseComponent } from '../../structural/base/base.component';

@Component({
    selector: 'app-toast',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent extends BaseComponent implements OnInit {
    show = false;

    private timer!: any;
    get toast(): ToastService {
        return this._toast;
    }

    constructor(private _toast: ToastService) {
        super(inject(Store<IAppState>));
    }

    ngOnInit(): void {
        this.toast.showToast$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
            this.show = value;
        });
    }

    close() {
        clearTimeout(this.timer);
        this.toast.hide();
    }
}
