import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { StaticFilesService } from '@api/static-files/static-files.service';
import { AmplitudeService } from '@services/amplitude.service';
import { BreakpointService } from '@services/breakpoint.service';
import { DynatraceService } from '@services/dynatrace.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { StepperService } from '@services/stepper.service';
import { LoaderComponent } from '@shared/components/atoms/loader/loader.component';
import { ToastComponent } from '@shared/components/atoms/toast/toast.component';
import { StepperComponent } from '@shared/components/molecules/stepper/stepper.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [StepperComponent, RouterOutlet, CommonModule, ToastComponent, LoaderComponent],
})
export class AppComponent {
    readonly stepper = inject(StepperService);
    readonly googleAnalyticsService = inject(GoogleAnalyticsService);
    readonly dynatraceService = inject(DynatraceService);
    readonly amplitudeService = inject(AmplitudeService);
    readonly staticFilesService = inject(StaticFilesService);
    readonly breakpointService = inject(BreakpointService);

    constructor() {
        this.amplitudeService.init();
        this.googleAnalyticsService.setupAnalytics();
        this.dynatraceService.createScript();
        this.staticFilesService.getStates().subscribe();
        this.staticFilesService.loadRoadsByState();
        this.breakpointService.set();
    }
}
