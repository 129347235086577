import { createReducer, on } from '@ngrx/store';
import { MenuItem } from '@shared/models/interfaces/menu-item.interface';
import { loadMenuItems, updateMenuItems } from '@store/actions/menu-items.actions';
import { MENU_ITEMS } from 'src/app/modules/dashboard/components/menu-sidebar/menu-items';

export const initialState: MenuItem[] = MENU_ITEMS;

export const menuItemsReducer = createReducer(
    initialState,
    on(loadMenuItems, (state) => {
        return {
            ...state,
        };
    }),
    on(updateMenuItems, (state, action) => {
        const items = [
            ...state.map((item) => {
                return {
                    ...item,
                    active: false,
                };
            }),
        ];
        const menuItems = items.map((item) => {
            if (item.name.toLowerCase() === action.menuItem.name.toLowerCase()) {
                return {
                    ...item,
                    active: true,
                };
            }
            return { ...item };
        });
        return [...menuItems];
    })
);
