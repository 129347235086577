import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { B2cService } from '@api/b2c/b2c.service';
import { ALLOWED_REQUEST_URL } from '@shared/constants/allowed-request-url.const';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const b2c = inject(B2cService);
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';

        if (this.checkUrlAllowed(request)) {
            request = request.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`),
            });
        }

        return next.handle(request).pipe(
            catchError((err) => {
                if ([401].includes(err.status)) {
                    b2c.logout();
                }
                if (err.status === 409 || err.status === 423) {
                    const { error } = err;
                    if (error.code && error.message) {
                        return throwError(() => ({
                            error: {
                                code: err.status,
                                message: error.message,
                                errorKey: error.code,
                            },
                        }));
                    } else {
                        return throwError(() => ({
                            error: {
                                code: err.status,
                                message: error.error.message,
                                errorKey: error.error.errorKey,
                            },
                        }));
                    }
                }
                return next.handle(request);
            })
        );
    }

    private checkUrlAllowed(request: HttpRequest<unknown>) {
        return ALLOWED_REQUEST_URL.some((url) => request.url.includes(url));
    }
}
