import { Routes } from '@angular/router';

import { featureFlagGuard } from '@core/data-access/feature-flags.guard';
import { DeepLinkGuard } from '@core/guards/deep-link.guard';
import { userResolver } from '@core/resolvers/user.resolver';

export const appRoutes: Routes = [
    { path: '', redirectTo: 'registration', pathMatch: 'full' },
    {
        path: 'registration',
        loadComponent: () => import('./modules/registration/registration.component').then((c) => c.RegistrationComponent),
        loadChildren: () => import('./modules/registration/registration-routes').then((routes) => routes.registrationRoutes),
    },
    {
        path: 'dashboard',
        loadComponent: () => import('./modules/dashboard/dashboard.component').then((c) => c.DashboardComponent),
        resolve: { user: userResolver },
    },
    {
        path: 'login',
        loadComponent: () => import('./modules/authentication/authentication.component').then((c) => c.AuthenticationComponent),
        loadChildren: () => import('./modules/authentication/authentication-routes').then((routes) => routes.authenticationRoutes),
    },
    {
        path: 'activity',
        loadComponent: () => import('./modules/activity/activity.component').then((c) => c.ActivityComponent),
        loadChildren: () => import('./modules/activity/actitity-routes').then((routes) => routes.activityRoutes),
        resolve: { user: userResolver },
        canMatch: [featureFlagGuard('activity'), DeepLinkGuard],
    },
    {
        path: 'payment-method',
        loadComponent: () => import('./modules/payment-method/payment-method.component').then((c) => c.PaymentMethodComponent),
        loadChildren: () => import('./modules/payment-method/payment-method-routes').then((routes) => routes.paymentMethodRoutes),
        resolve: { user: userResolver },
        canMatch: [featureFlagGuard('payment-method'), DeepLinkGuard],
    },
    {
        path: 'vehicles',
        loadComponent: () => import('./modules/vehicles/vehicles.component').then((c) => c.VehiclesComponent),
        loadChildren: () => import('./modules/vehicles/vehicles-routes').then((routes) => routes.vehiclesRoutes),
        resolve: { user: userResolver },
        canMatch: [featureFlagGuard('vehicles'), DeepLinkGuard],
    },
    {
        path: 'rewards-wallet',
        loadComponent: () => import('./modules/rewards-wallet/rewards-wallet.component').then((c) => c.RewardsWalletComponent),
        loadChildren: () => import('./modules/rewards-wallet/rewards-wallet-routes').then((routes) => routes.rewardsWalletRoutes),
        resolve: { user: userResolver },
        canMatch: [featureFlagGuard('rewards-wallet'), DeepLinkGuard],
    },
    {
        path: 'personal-information',
        loadComponent: () =>
            import('./modules/personal-information/personal-information.component').then((c) => c.PersonalInformationComponent),

        loadChildren: () =>
            import('./modules/personal-information/personal-information-routes').then((routes) => routes.personalInformationRoutes),
        resolve: { user: userResolver },
        canMatch: [featureFlagGuard('personal-information'), DeepLinkGuard],
    },
    // {
    //     path: 'personal-information',
    //     loadComponent: () =>
    //         import('./modules/personal-information/personal-information.component').then((c) => c.PersonalInformationComponent),
    // },
    {
        path: '**',
        redirectTo: 'registration',
    },
];
