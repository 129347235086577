import { CommonModule } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderService } from '@services/loader.service';
import { PositionLoader } from '@shared/models/enums/position-loader';
import { PositionLoaderData } from '@shared/models/interfaces/position-loader.interface';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    imports: [CommonModule, TranslateModule],
})
export class LoaderComponent implements OnDestroy {
    show = false;
    position: PositionLoader = PositionLoader.right;

    private $loaderSubscription!: Subscription;

    get loader(): LoaderService {
        return this._loader;
    }

    constructor(private _loader: LoaderService) {
        this.$loaderSubscription = this.loader.showLoader$.subscribe((value: PositionLoaderData) => {
            this.show = value.flag;
            this.position = value.position;
        });
    }

    ngOnDestroy(): void {
        this.$loaderSubscription.unsubscribe();
    }
}
