import { createReducer, on } from '@ngrx/store';
import { FiltersDto } from '@shared/models/interfaces/filters.interface';
import { loadTollsFilters, updateTollsFilters } from '@store/actions/activity-tolls-filters.actions';

export const initialState: FiltersDto = {
    dateRange: {
        selected: false,
        range: { from: '', to: '' },
    },
    datePeriod: {
        selected: false,
        years: [],
        months: [],
    },
    vehicleType: [],
    licensesPlate: [],
    // Todo:remove-roads
    // roads: [],
};

export const activityTollsFiltersReducer = createReducer(
    initialState,
    on(loadTollsFilters, (state) => {
        return {
            ...state,
        };
    }),
    on(updateTollsFilters, (state, action) => {
        return {
            ...state,
            ...action.tollsFilters,
        };
    })
);
