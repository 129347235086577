import { Injectable } from '@angular/core';
import { PositionLoader } from '@shared/models/enums/position-loader';
import { PositionLoaderData } from '@shared/models/interfaces/position-loader.interface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private showLoaderSubject$ = new BehaviorSubject<PositionLoaderData>({
        position: PositionLoader.right,
        flag: false,
    });

    show(position?: PositionLoader) {
        const positionLoaderData: PositionLoaderData = {
            position: position ? position : PositionLoader.right,
            flag: true,
        };
        this.showLoaderSubject$.next(positionLoaderData);
    }

    hide() {
        const positionLoaderData: PositionLoaderData = {
            position: PositionLoader.right,
            flag: false,
        };
        this.showLoaderSubject$.next(positionLoaderData);
    }

    get showLoader$() {
        return this.showLoaderSubject$.asObservable();
    }
}
