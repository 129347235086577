export const environment = {
    production: false,
    trackId: 'G-58GPLC8ZPB',
    amplitude_key: '933554cf00f9cc886918a7dfadb36f43',
    help_support_url: 'https://www.nextpass.io/help-center/',
    terms_and_conditions_url: 'https://steuddtpaweb01.z20.web.core.windows.net/legal/terms_and_conditions.html',
    privacy_policy_url: 'https://steuddtpaweb01.z20.web.core.windows.net/legal/privacy_policy.html',
    terms_and_conditions_url_origin: 'https://steuddtpaweb01.z20.web.core.windows.net',
    api_static_files: 'https://steuddtpaweb01.z20.web.core.windows.net',
    home_url: 'https://www.nextpass.io',
    email_support: 'support@nextpass.io',
    phone: '+1 888 814 7766',

    url: 'https://apim-eu-d-dtpa-01.azure-api.net/api/v1',
    B2C_CNAME: 'loginnonprod.nextpass.io',
    B2C_TENANT: 'dptanonprod.onmicrosoft.com',
    POLICY: 'B2C_1A_SIGNUP_SIGNIN_SOCIAL_DEV',
    APPLICATION_ID: 'f3620d47-7b72-428c-800f-37ce350c66c2',
    APPLICATION_URL: 'https%3A%2F%2Fappdev.nextpass.io%2Fregistration%2Fauth',
    DYNATRACE_ID: '642261c292ac7dea',
};
