import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomBreakpoints } from '@shared/models/enums/customBreakpoints';
import { Breakpoint } from '@shared/models/interfaces/breakpoint.interface';
import { updateBreakpoint } from '@store/actions/breakpoint.actions';

@Injectable({
    providedIn: 'root',
})
export class BreakpointService {
    breakpoint = '';

    constructor(private breakpointObserver: BreakpointObserver, private $store: Store<{ breakpoint: string }>) {}

    set(): void {
        this.breakpointObserver.observe(Object.values(CustomBreakpoints)).subscribe((result) => {
            const breakpointString = Object.keys(CustomBreakpoints).find(
                (key) => result.breakpoints[CustomBreakpoints[key as keyof typeof CustomBreakpoints]]
            );

            if (breakpointString) {
                this.breakpoint = breakpointString;

                const breakpoint: Breakpoint = {
                    breakpoint: breakpointString,
                };
                this.$store.dispatch(updateBreakpoint({ breakpoint: breakpoint }));
            }
        });
    }

    minorEqual(targetBreakpoint: string): boolean {
        const breakpointsArray = Object.keys(CustomBreakpoints);

        const currentIndex = breakpointsArray.indexOf(this.breakpoint);
        const targetIndex = breakpointsArray.indexOf(targetBreakpoint);

        if (currentIndex === -1 || targetIndex === -1) {
            return true;
        }

        return currentIndex <= targetIndex;
    }

    majorEqual(targetBreakpoint: string): boolean {
        const breakpointsArray = Object.keys(CustomBreakpoints);

        const currentIndex = breakpointsArray.indexOf(this.breakpoint);
        const targetIndex = breakpointsArray.indexOf(targetBreakpoint);
        if (currentIndex === -1 || targetIndex === -1) {
            return false;
        }

        return currentIndex >= targetIndex;
    }
}
