import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { FeatureFlags } from '../utils/feature-flags';

@Injectable({
    providedIn: 'root',
})
export class FeatureFlagsDataService {
    readonly #http = inject(HttpClient);

    getFeatureFlags() {
        return this.#http.get<FeatureFlags>('../../../../assets/feature-flags/feature-flags.manifest.json');
    }
}
