import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { BreakpointService } from '@services/breakpoint.service';
import { PositionLoader } from '@shared/models/enums/position-loader';
import { Breakpoint } from '@shared/models/interfaces/breakpoint.interface';
import { IAppState } from '@store/app.state';
import { selectBreakpoint$ } from '@store/selectors/breakpoint.selectors';

@Component({
    template: '',
    standalone: true,
    imports: [CommonModule],
})
export class BaseComponent {
    protected breakpoint!: string;
    protected positionLoader!: PositionLoader;
    protected _breakpointService = inject(BreakpointService);

    protected readonly destroyRef = inject(DestroyRef);

    constructor(protected readonly $store: Store<IAppState>) {
        // this.initCommonFields();
        this.$store
            .select(selectBreakpoint$)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((state: Breakpoint) => {
                this.breakpoint = state.breakpoint;
                this.positionLoader = this._breakpointService.majorEqual('desktop') ? PositionLoader.right : PositionLoader.center;
            });
    }

    // ngOnDestroy(): void {
    //     this.subscriptionsActive = false;
    // }

    // private initCommonFields(): void {
    //     this.subscriptionsActive = true;
    // }
}
