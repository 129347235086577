import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { StaticRoads } from '@shared/models/interfaces/highway.interface';
import { State } from '@shared/models/interfaces/state.interface';
import { BehaviorSubject, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
    providedIn: 'root',
})
export class StaticFilesService {
    private _states: State[] = [];
    private readonly APP_CONFIG_URL = '/app-config/common/';
    private readonly ALLOWED_ROADS_JSON = 'roadsCompabilityStates.json';
    private readonly US_STATES_JSON = 'usstates.json';

    private dataRoadsByStateLoaded = new BehaviorSubject<boolean>(false);
    private dataRoadsByState = new BehaviorSubject<StaticRoads | null>(null);

    constructor(private http: HttpClient) {}

    getStates(): Observable<State[]> {
        return this.http.get<{ states: State[] }>(`${environment.api_static_files}${this.APP_CONFIG_URL}${this.US_STATES_JSON}`).pipe(
            map((response) => {
                this._states = response.states;
                return response.states;
            })
        );
    }

    getUsStates() {
        return this._states;
    }

    getRoadsByState(): Observable<StaticRoads | null> {
        return this.dataRoadsByState.asObservable();
    }

    loadRoadsByState() {
        if (!this.dataRoadsByStateLoaded.getValue()) {
            this.http
                .get<StaticRoads>(`${environment.api_static_files}${this.APP_CONFIG_URL}${this.ALLOWED_ROADS_JSON}`)
                .pipe(
                    tap((data: StaticRoads) => {
                        this.dataRoadsByStateLoaded.next(true);
                        this.dataRoadsByState.next(data);
                    })
                )
                .subscribe();
        }
    }
}
