import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DynatraceService {
    private url = 'https://js-cdn.dynatrace.com/jstag/165658ccba3/bf53975nku/';

    createScript() {
        const script = document.createElement('script');
        script.async = true;
        script.src = `${this.url}${environment.DYNATRACE_ID}_complete.js`;
        document.head.prepend(script);
    }
}
