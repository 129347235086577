/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { microservices } from '@api/microservices.type';
import { environment } from '@environments/environment';
import { AmplitudeService } from '@services/amplitude.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import {
    B2cCreateUser,
    B2CLoginResponse,
    B2CLoginUser,
    CompleteSignupDTO,
    PostUserTokenResponse,
    SignupIntentDTO,
    SignupIntentResponse,
    SignupOTPCodeDTO,
} from '@shared/models/interfaces/b2c.interface';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class B2cService {
    readonly http = inject(HttpClient);
    readonly router = inject(Router);
    readonly amplitudeService = inject(AmplitudeService);
    readonly googleAnalyticsService = inject(GoogleAnalyticsService);

    redirectUrl: string | null = null;

    private readonly endpoint = '/users';
    private readonly endpointOtp = '/otp';
    private readonly forgotPasswordCheckEmail = '/forgot-password';

    createUser(user: B2cCreateUser) {
        return this.http.post(`${environment.url}${microservices.b2c}${this.endpoint}`, user);
    }

    signUpIntent({ firstName, lastName, email }: SignupIntentDTO): Observable<SignupIntentResponse> {
        const body = {
            firstName,
            lastName,
            email,
        };
        return this.http.post<SignupIntentResponse>(`${environment.url}${microservices.b2c}${this.endpoint}/signup`, body);
    }

    checkSignupOtp(token: string, { code }: SignupOTPCodeDTO): Observable<boolean> {
        const body = { code };
        return this.http.post<boolean>(`${environment.url}${microservices.b2c}${this.endpoint}/signup/${token}/check-otp`, body);
    }

    signUpComplete(token: string, { password }: CompleteSignupDTO): Observable<PostUserTokenResponse> {
        const body = { password };
        return this.http
            .post<PostUserTokenResponse>(`${environment.url}${microservices.b2c}${this.endpoint}/signup/${token}/complete`, body)
            .pipe(
                map((response: PostUserTokenResponse) => {
                    localStorage.setItem('token', response.access_token);
                    return response;
                })
            );
    }

    loginUser(user: B2CLoginUser) {
        const body = {
            username: user.email,
            password: user.password,
        };
        return this.http.post<B2CLoginResponse>(`${environment.url}${microservices.b2c}${this.endpoint}/login`, body).pipe(
            map((response: B2CLoginResponse) => {
                localStorage.setItem('token', response['access_token']);
            })
        );
    }

    logout() {
        localStorage.removeItem('token');
        this.amplitudeService.track('log_out');
        this.googleAnalyticsService.track('log_out');
        this.router.navigate(['login']);
    }

    getOtp(name: string, email: string) {
        const body = { name, email };
        return this.http.post<string>(`${environment.url}${microservices.b2c}${this.endpointOtp}/get`, body);
    }

    checkOtp(email: string, code: number) {
        const body = { email, code };
        return this.http.post<boolean>(`${environment.url}${microservices.b2c}${this.endpointOtp}/check`, body).pipe(
            catchError(() => {
                return of(false);
            })
        );
    }

    checkEmailForgotPassword(email: string) {
        const body = { email };
        return this.http.post(`${environment.url}${microservices.b2c}${this.endpoint}${this.forgotPasswordCheckEmail}`, body);
    }

    checkOtpForgotPassword(code: string, resetPasswordToken: string) {
        const body = { code, resetPasswordToken };
        return this.http.post<boolean>(`${environment.url}${microservices.b2c}${this.endpointOtp}/check-forgot-password`, body);
    }

    changePassword(password: string, resetPasswordToken: string) {
        const body = { password, resetPasswordToken };
        return this.http.post(`${environment.url}${microservices.b2c}${this.endpoint}/reset-password`, body);
    }

    isLogged(): boolean {
        return !!localStorage.getItem('token');
    }
}
