import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { B2cService } from '@api/b2c/b2c.service';

export const DeepLinkGuard: CanMatchFn = async (route: Route, segments: UrlSegment[]) => {
    const b2cService = inject(B2cService);
    const router = inject(Router);

    if (b2cService.isLogged()) {
        return true;
    } else {
        b2cService.redirectUrl = segments.map((segment) => segment.path).join('/');
        router.navigate(['/login']);
    }
    return false;
};
