import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AmplitudeService {
    init() {
        amplitude.init(environment.amplitude_key, '', {
            defaultTracking: {
                pageViews: false,
                sessions: false,
                formInteractions: false,
                fileDownloads: false,
            },
        });
    }

    track(eventName: string, eventData?: any) {
        return amplitude.track(eventName, eventData);
    }
}
