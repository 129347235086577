import { createReducer, on } from '@ngrx/store';
import { User } from '@shared/models/interfaces/user.interface';
import { loadUser } from '@store/actions/user.actions';

export const initialState: User = {
    name: '',
    surname: '',
    email: '',
    identityProvider: '',
    vehicleCreated: false,
    paymentMethodRegistered: false,
    status: 'PENDING',
    paymentAttempts: 0,
    maxPaymentAttempts: 0,
    allVehiclesDeleted: false,
    hasTransponder: false,
    registrationChannel: '',
    termsAndConditionsVersion: '',
    privacyPolicyVersion: '',
    id: '',
};

export const userReducer = createReducer(
    initialState,
    on(loadUser, (state, action) => {
        return {
            ...state,
            ...action.user,
        };
    })
);
