import { MenuItem } from '@shared/models/interfaces/menu-item.interface';

export const MENU_ITEMS: MenuItem[] = [
    {
        name: 'MENU_SIDEBAR.DASHBOARD',
        imgSrc: '../../../../../assets/icons/dashboard.svg',
        active: true,
        hover: false,
        navigateTo: 'dashboard',
        featureFlag: 'dashboard',
    },
    {
        name: 'MENU_SIDEBAR.ACTIVITY',
        imgSrc: '../../../../../assets/icons/activity.svg',
        active: false,
        hover: false,
        navigateTo: 'activity',
        featureFlag: 'activity',
    },
    {
        name: 'MENU_SIDEBAR.VEHICLES',
        imgSrc: '../../../../../assets/icons/vehicle_cut.svg',
        active: false,
        hover: false,
        navigateTo: 'vehicles',
        featureFlag: 'vehicles',
    },
    {
        name: 'MENU_SIDEBAR.PAYMENT_INF',
        imgSrc: '../../../../../assets/icons/credit-card.svg',
        active: false,
        hover: false,
        navigateTo: 'payment-method',
        featureFlag: 'payment-method',
    },
    {
        name: 'MENU_SIDEBAR.REWARDS_WALLET',
        imgSrc: '../../../../../assets/icons/benefits.svg',
        active: false,
        hover: false,
        navigateTo: 'rewards-wallet',
        featureFlag: 'rewards-wallet',
    },
    {
        name: 'MENU_SIDEBAR.PERSONAL_INF',
        imgSrc: '../../../../../assets/icons/user.svg',
        active: false,
        hover: false,
        navigateTo: 'personal-information',
        featureFlag: 'personal-information',
    },
    {
        name: 'MENU_SIDEBAR.HELP_SUPPORT',
        imgSrc: '../../../../../assets/icons/help.svg',
        active: false,
        hover: false,
        navigateTo: 'dashboard',
        featureFlag: 'dashboard',
    },
    {
        name: 'MENU_SIDEBAR.SIGN_OUT',
        imgSrc: '../../../../../assets/icons/log-out.svg',
        active: false,
        hover: false,
        navigateTo: 'login',
        featureFlag: 'authentication',
    },
];
