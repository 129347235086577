<div
    class="toast toast-{{ toast.from }}
        toast-{{ toast.type }}
        toast-{{ breakpoint }}"
    [ngClass]="show ? toast.from + '-show-' + breakpoint : ''"
>
    <div class="toast__body">
        <section class="toast__body__info">
            <div class="toast__body__info--title" *ngIf="toast.title !== ''">
                {{ toast.title | translate }}
            </div>
            <div class="toast__body__info--description">
                {{ toast.label | translate }} {{ toast.var }}
                @if (toast.list && toast.list.length > 0) {
                <ul>
                    @for (list of toast.list; track toast.list) {
                    <li>{{ list | translate }}</li>
                    }
                </ul>
                }
            </div>
        </section>
        <section class="toast__body__button">
            <img
                src="./../../../../assets/icons/close.svg"
                alt="{{ 'TOAST.CLOSE' | translate }} "
                (click)="close()"
                class="img-{{ toast.type }}"
            />
        </section>
    </div>
</div>
