import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AccountsService } from '@api/accounts/accounts.service';
import { Store } from '@ngrx/store';
import { User } from '@shared/models/interfaces/user.interface';
import { loadUser } from '@store/actions/user.actions';
import { IAppState } from '@store/app.state';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { delay } from 'rxjs/internal/operators/delay';
import { Subject } from 'rxjs/internal/Subject';

export const userResolver: ResolveFn<User | Observable<boolean>> | any = async (route: ActivatedRouteSnapshot) => {
    let retries = 0;
    const MAX_RETRIES = 3;
    const subject = new Subject();
    const accountService = inject(AccountsService);
    const store = inject(Store<IAppState>);
    const makeRequest = () => {
        if (retries < MAX_RETRIES) {
            retries++;

            accountService.getUser().subscribe((res) => {
                if (!res.vehicleCreated) {
                    of(null)
                        .pipe(delay(1000))
                        .subscribe(() => makeRequest());
                } else {
                    subject.next(res);
                }
            });
        } else {
            subject.next(null);
        }
    };
    if (
        'setup_intent' in route.queryParams &&
        !route.routeConfig?.path?.includes('payment-method') &&
        !route.routeConfig?.path?.includes('dashboard')
    ) {
        makeRequest();
        return subject.asObservable();
    } else {
        const user = await firstValueFrom(accountService.getUser());
        store.dispatch(loadUser({ user }));
        return user;
    }
};
