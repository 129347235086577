import { Injectable } from '@angular/core';
import { StepsState } from '@enums/steps-state';
import { StepsInterface } from '@interfaces/steps.interface';
import { steps } from '@shared/components/molecules/stepper/const/steps';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StepperService {
    private _steps = steps;

    private stepperObservable: BehaviorSubject<StepsInterface[]> = new BehaviorSubject<StepsInterface[]>(this._steps);

    get getStepperObservable() {
        return this.stepperObservable.asObservable();
    }

    set setStepperObservable(_steps: StepsInterface[]) {
        this.stepperObservable.next(_steps);
    }

    get steps(): StepsInterface[] {
        return this._steps;
    }

    activatedToggle(index: number): void {
        this._steps[index - 1].state = StepsState.activated;
        this.setStepperObservable = this._steps;
    }

    completedToggle(index: number): void {
        this._steps[index - 1].state = StepsState.completed;
        this.setStepperObservable = this._steps;
    }

    skippedToggle(index: number): void {
        this._steps[index - 1].state = StepsState.skipped;
        this.setStepperObservable = this._steps;
    }

    pendingToggle(index: number): void {
        this._steps[index - 1].state = StepsState.pending;
        this.setStepperObservable = this._steps;
    }
}
