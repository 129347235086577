import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { FeatureFlagsFacadeService } from './facades/feature-flags-facade.service';
import { FeatureFlag } from './utils/feature-flags';

export function featureFlagGuard(featureFlag: FeatureFlag): CanMatchFn {
    return () => {
        const featureFlagsFacade = inject(FeatureFlagsFacadeService);
        const enabled = featureFlagsFacade.featureFlagEnabled(featureFlag);
        return enabled;
    };
}
