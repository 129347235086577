import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { microservices } from '@api/microservices.type';
import { environment } from '@environments/environment';
import { User } from '@shared/models/interfaces/user.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AccountsService {
    constructor(private http: HttpClient) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    create() {
        const body = {
            registrationChannel: 'WEB',
        };
        return this.http.post(`${environment.url}${microservices.accounts}/users`, body);
    }

    getUser(): Observable<User> {
        return this.http.get<User>(`${environment.url}${microservices.accounts}/user`);
    }

    delete(feedback: string) {
        const body = {
            body: {
                feedback: feedback,
            },
        };
        return this.http.delete(`${environment.url}${microservices.accounts}/users`, body);
    }
}
