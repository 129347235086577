import { createReducer, on } from '@ngrx/store';
import { FiltersDto } from '@shared/models/interfaces/filters.interface';
import { loadPaymentsFilters, updatePaymentsFilters } from '@store/actions/activity-payments-filters.actions';

export const initialState: FiltersDto = {
    dateRange: {
        selected: false,
        range: { from: '', to: '' },
    },
    datePeriod: {
        selected: false,
        years: [],
        months: [],
    },
    vehicleType: [],
    licensesPlate: [],
    // Todo:remove-roads
    // roads: [],
};

export const activityPaymentsFiltersReducer = createReducer(
    initialState,
    on(loadPaymentsFilters, (state) => {
        return {
            ...state,
        };
    }),
    on(updatePaymentsFilters, (state, action) => {
        return {
            ...state,
            ...action.paymentsFilters,
        };
    })
);
