import { createReducer, on } from '@ngrx/store';
import { updateBreakpoint } from '@store/actions/breakpoint.actions';

export const initialState = { breakpoint: '' };

export const breakpointReducer = createReducer(
    initialState,
    on(updateBreakpoint, (state, action) => {
        return {
            ...state,
            ...action.breakpoint,
        };
    })
);
