import { ActionReducerMap } from '@ngrx/store';
import { Breakpoint } from '@shared/models/interfaces/breakpoint.interface';
import { FiltersDto } from '@shared/models/interfaces/filters.interface';
import { MenuItem } from '@shared/models/interfaces/menu-item.interface';
import { User } from '@shared/models/interfaces/user.interface';

import { activityPaymentsFiltersReducer } from './reducers/activity-payments-filters.reducer';
import { activityTollsFiltersReducer } from './reducers/activity-tolls-filters.reducer';
import { breakpointReducer } from './reducers/breakpoint.reducer';
import { menuItemsReducer } from './reducers/menu-items.reducer';
import { userReducer } from './reducers/user.reducer';

export interface IAppState {
    menuItemsState: MenuItem[];
    userState: User;
    activityTollsState: FiltersDto;
    activityPaymentsState: FiltersDto;
    breakpointState: Breakpoint;
}

export const APP_REDUCERS: ActionReducerMap<IAppState> = {
    menuItemsState: menuItemsReducer,
    userState: userReducer,
    activityTollsState: activityTollsFiltersReducer,
    activityPaymentsState: activityPaymentsFiltersReducer,
    breakpointState: breakpointReducer,
};
