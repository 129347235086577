import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { GoogleAnalyticsEvent } from '@shared/models/interfaces/google-analytics-event.interface';
import { filter } from 'rxjs/internal/operators/filter';

declare const gtag: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor(private router: Router) {}

    setupAnalytics() {
        this.createScript();
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
            gtag('event', 'page_view', {
                page_path: event.urlAfterRedirects,
            });
        });
    }
    registerClickEvent(event: GoogleAnalyticsEvent) {
        const options = {
            event_category: event.category,
            event_label: event.label,
            event_value: event.value,
        };
        if ('action' in event) {
            Object.assign({ event_action: event.action });
        }
        return gtag('event', event.name, options);
    }
    registerPage(path: string) {
        gtag('event', 'page_view', {
            page_path: path,
        });
    }

    track(event: string) {
        gtag('event', event);
    }
    private createScript() {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.trackId}`;
        document.head.prepend(script);
        gtag('config', environment.trackId);
    }
}
