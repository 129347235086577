import { StepsState } from '@enums/steps-state';
import { StepsInterface } from '@interfaces/steps.interface';

const urlIcon = '../../../assets/icons/';

export const steps: StepsInterface[] = [
    {
        url: `${urlIcon}icon-email.svg`,
        info: 'Sign up and verify your email.',
        alt: 'Login',
        state: StepsState.pending,
    },
    {
        url: `${urlIcon}icon-license-plate.svg`,
        info: 'Enter your vehicle information.',
        alt: 'License plate',
        state: StepsState.pending,
    },
    {
        url: `${urlIcon}icon-credit-card.svg`,
        info: 'Complete your payment information.',
        alt: 'Payment information',
        state: StepsState.pending,
    },
];
